module.exports = [{
      plugin: require('/home/kuba/develop/deployedpl/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-41819992-1"],"head":true,"alwaysSendReferrer":true},
    },{
      plugin: require('/home/kuba/develop/deployedpl/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/kuba/develop/deployedpl/src/components/layout.js"},
    },{
      plugin: require('/home/kuba/develop/deployedpl/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
