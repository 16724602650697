// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/home/kuba/develop/deployedpl/src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-list-js": () => import("/home/kuba/develop/deployedpl/src/templates/blogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-project-post-js": () => import("/home/kuba/develop/deployedpl/src/templates/projectPost.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-career-post-js": () => import("/home/kuba/develop/deployedpl/src/templates/careerPost.js" /* webpackChunkName: "component---src-templates-career-post-js" */),
  "component---src-pages-404-js": () => import("/home/kuba/develop/deployedpl/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-index-js": () => import("/home/kuba/develop/deployedpl/src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-contact-js": () => import("/home/kuba/develop/deployedpl/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-we-work-js": () => import("/home/kuba/develop/deployedpl/src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("/home/kuba/develop/deployedpl/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("/home/kuba/develop/deployedpl/src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-portfolio-index-js": () => import("/home/kuba/develop/deployedpl/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-thank-you-js": () => import("/home/kuba/develop/deployedpl/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

